import { EntityType, Role } from "Common/Constants";
import { IActionButton } from "./Interfaces";
import { useIsActionAuthorized } from "Common/Hooks";
import { MiniSideBarButton } from "../MiniSideBarButton";
import { SettingOutlined } from "Icons/Icons";
import { getActionButtonRoute } from "./Functions";
import { $Tooltip } from "Common/Components";

export /**
 *
 * Generate the Action button based on the logged in user authorization
 * @param {*} props Component authorization
 * @return {*} JSX.Element
 */
const ActionButton: React.FC<IActionButton> = (props) => {
  const { authDetails } = useIsActionAuthorized();
  const { authorizationMetadata } = authDetails ?? {};

  switch (`${authorizationMetadata?.entityType}-${authorizationMetadata?.roleName}`) {
    case `${EntityType.Corporate}-${Role.ADMIN}`:
      return (
        <MiniSideBarButton
          name={"Admin Center"}
          isActive={false}
          icon={<SettingOutlined />}
          onClick={(e) => {
            e.preventDefault();
            window.open(getActionButtonRoute(authorizationMetadata), "_blank");
          }}
        />
      );

    case `${EntityType.Division}-${Role.ADMIN}`:
      return (
        <$Tooltip title={authorizationMetadata?.entityName} placement="right" arrow>
          <div>
            <MiniSideBarButton
              name={"Admin Center"}
              isActive={false}
              icon={<SettingOutlined />}
              onClick={(e) => {
                e.preventDefault();
                window.open(getActionButtonRoute(authorizationMetadata), "_blank");
              }}
            />
          </div>
        </$Tooltip>
      );
    case `${EntityType.Provider}-${Role.ADMIN}`:
      return (
        <$Tooltip title={authorizationMetadata?.entityName} placement="right" arrow>
          <div>
            <MiniSideBarButton
              name={"Admin Center"}
              isActive={false}
              icon={<SettingOutlined />}
              onClick={(e) => {
                e.preventDefault();
                window.open(getActionButtonRoute(authorizationMetadata), "_blank");
              }}
            />
          </div>
        </$Tooltip>
      );
    case `${EntityType.Customer}-${Role.ADMIN}`:
      return (
        <$Tooltip title={authorizationMetadata?.entityName} placement="right" arrow>
          <div>
            <MiniSideBarButton
              name={"Admin Center"}
              isActive={false}
              icon={<SettingOutlined />}
              onClick={(e) => {
                e.preventDefault();
                window.open(getActionButtonRoute(authorizationMetadata), "_blank");
              }}
            />
          </div>
        </$Tooltip>
      );

    default:
      return <></>;
  }
};
