import { AppBar, Box, Stack, Toolbar } from "@mui/material";
import TestEnvBanner from "./TestEnvBanner";
import Logo from "Common/Components/Logo";
import { AppsMenu } from "./AppsMenu";
import { Settings } from "./Settings";
import { Profile } from "./Profile";

interface IHeader {
  handleProfileDrawerOpen: () => void;
}

export const Header: React.FC<IHeader> = ({ handleProfileDrawerOpen }) => {
  return (
    <AppBar position="fixed" sx={{ zIndex: 1300 }}>
      <Toolbar>
        <TestEnvBanner />
        <Box flex={1}>
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <AppsMenu />
            <Logo to={process.env.REACT_APP_LANDING_PAGE_URL} />
          </Stack>
        </Box>
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <Settings />
          <Profile handleProfileDrawerOpen={handleProfileDrawerOpen} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
