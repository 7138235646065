import { useTheme } from "@mui/material";
import { $Box, $Grid, $Stack, $Typography, CollapseButton } from "Common/Components";
import { MiniSidebar } from "./Components";
import { OurBrandPanel } from "./Components/OurBrandPanel";
import { WidgetTile } from "./Components/WidgetTile";
import { MainSlider } from "./Components/MainSlider";
import { useMatchDownMd } from "Common/Hooks";
import { useState } from "react";
import { MobileSidebar } from "Common/Components/MobileSidebar";
import { COLLAPSED_SIDEBAR_WIDTH, SIDEBAR_WIDTH } from "Common/Constants";
import { SideBar } from "./Components/SideBar/SideBar";

export const Dashboard = () => {
  const theme = useTheme();

  const { matchDownMd } = useMatchDownMd();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isInsideCollapsed, setInsideCollapsed] = useState<boolean>(false);

  const [drawer, setDrawer] = useState(false);

  return (
    <>
      <$Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        <$Grid
          container
          sx={{
            height: "100%",
            overflow: "auto",
          }}
        >
          {/* Mini sidebar */}
          {matchDownMd ? (
            <CollapseButton
              isCollapsed={isCollapsed}
              onClick={() => {
                setDrawer(!drawer);
                setIsCollapsed(!isCollapsed);
              }}
              sx={{
                backgroundColor: theme.colors.alpha.white[100],
                left: isCollapsed ? 0 : 215,
                top: "calc(50% - 48px)",
                transform: "translate(0%, -50%)",
                zIndex: 1200,
              }}
            />
          ) : (
            <MiniSidebar setIsCollapsed={setInsideCollapsed} isCollapsed={isInsideCollapsed} />
          )}
          {/* Content */}
          <$Box
            sx={(theme) => ({
              height: "100%",
              [theme.breakpoints.up("md")]: {
                width: isInsideCollapsed ? `calc(100% - ${COLLAPSED_SIDEBAR_WIDTH}px)` : `calc(100% - ${SIDEBAR_WIDTH}px)`,
                transition: theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
                ...(isCollapsed && {
                  transition: theme.transitions.create("width", {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                }),
              },
              [theme.breakpoints.down("md")]: {
                width: "100%",
                height: "auto",
              },
            })}
          >
            <$Box
              sx={{
                padding: 2,
                height: "auto",
              }}
            >
              <$Grid container mb={1.5}>
                <$Grid item>
                  <$Typography
                    variant="h3"
                    sx={{
                      fontWeight: theme.typography.fontWeightMedium,
                    }}
                  >
                    Welcome to AMI Home
                  </$Typography>
                </$Grid>
              </$Grid>

              <$Grid container spacing={0} sx={{ mb: 3 }}>
                <$Grid item xs={12} md={12}>
                  <$Box
                    sx={{
                      backgroundColor: theme.palette.grey[300],
                    }}
                  ></$Box>
                  <MainSlider />
                </$Grid>
              </$Grid>

              <$Grid container sx={{ backgroundColor: theme.palette.common.white, pb: 3, mb: 3 }} spacing={0}>
                <$Grid item zeroMinWidth xs={12} md={12} sx={{ p: 2 }}></$Grid>
                <$Grid item zeroMinWidth xs={12} md={12}>
                  <$Stack
                    direction="row"
                    spacing={1}
                    alignItems="stretch"
                    justifyContent="space-evenly"
                    sx={{ flexWrap: { xs: "wrap", sm: "inherit", md: "inherit" } }}
                  >
                    <OurBrandPanel
                      linkURL="https://www.taylor-company.com/"
                      logoURL="https://www.taylor-company.com/wp-content/uploads/2023/01/Taylor_Crown_small.png"
                    />

                    <OurBrandPanel
                      linkURL="https://www.pitco.com/"
                      logoURL="https://www.pitco.com/wp-content/uploads/2022/02/pitco-logo-1.png"
                    />
                    <OurBrandPanel
                      linkURL="https://www.middlebymarshall.com/"
                      logoURL="https://www.middlebymarshall.com/wp-content/uploads/2020/09/mm-logo-2.png"
                    />
                    <OurBrandPanel
                      linkURL="https://turbochef.com/"
                      logoURL="https://turbochef.com/wp-content/uploads/2017/08/turbochef-logo.svg"
                    />
                    <OurBrandPanel
                      linkURL="https://www.blodgett.com/"
                      logoURL="https://www.blodgett.com/wp-content/uploads/2021/01/logo.png"
                    />
                    <OurBrandPanel
                      linkURL="https://middlebycoffee.com/"
                      logoURL="https://stamideveus.blob.core.windows.net/landing-page-images/COFFEE.jpg"
                    />
                  </$Stack>
                </$Grid>
              </$Grid>

              <$Grid container sx={{ pb: 2 }} spacing={1}>
                <WidgetTile
                  tileTitle="What's new"
                  linkURL={`${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-files/What's_New.pdf`}
                  tileImage={`${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-images/w1.jpg`}
                />
                <WidgetTile
                  linkURL="https://www.middlebyadvantage.com/find-authorized-service"
                  tileTitle="Find Authorized Service"
                  tileImage={`${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-images/w2.jpg`}
                />
                <WidgetTile
                  linkURL="https://www.middlebyadvantage.com/"
                  tileTitle="One Stop Shop"
                  tileImage={`${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-images/w3.jpg`}
                />

                <WidgetTile
                  tileTitle="Innovation Kitchens"
                  linkURL="https://www.middleby.com/middleby-innovation-kitchens-mik/"
                  tileImage={`${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-images/w4.jpg`}
                />
                <WidgetTile
                  tileTitle="Consultant Corner"
                  linkURL="https://www.middleby.com/consultant-corner/"
                  tileImage={`${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-images/w5.jpg`}
                />
                <WidgetTile
                  tileTitle="Food Processing"
                  linkURL="https://www.middleby.com/brand_category/food-processing/"
                  tileImage={`${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-images/w6.jpg`}
                />
              </$Grid>
            </$Box>
          </$Box>
        </$Grid>
      </$Box>
      {matchDownMd && (
        <MobileSidebar isOpen={drawer}>
          <SideBar
            permissions={[]}
            handleCollapse={() => {
              setDrawer(false);
              setIsCollapsed(true);
            }}
          />
        </MobileSidebar>
      )}
    </>
  );
};
