import { $Box, $Divider, $Stack, CollapseButton } from "Common/Components";
import { MiniSideBarButton } from "../MiniSideBarButton";
import { ActionButton } from "../ActionButton";
import { useTheme } from "@mui/material";
import { SideBarAppButton } from "../SideBarAppButton";
import { useGetUserApplicationsQuery } from "Store/Services/Users";
import { useMatchDownMd } from "Common/Hooks";
import { SIDEBAR_WIDTH } from "Common/Constants";
import { HomeOutlined } from "Icons/Icons";

interface IMiniSideBar {
  handleCollapse?: () => void;
  isCollapsed?: boolean;
  setIsCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MiniSidebar = ({ handleCollapse, isCollapsed, setIsCollapsed }: IMiniSideBar) => {
  const theme = useTheme();
  const { data } = useGetUserApplicationsQuery({
    isDeleted: false,
    limit: 10,
    offset: 0,
    orderBy: "name",
    search: "",
    sortBy: "1",
  });
  const { data: applications } = data ?? {};
  const { matchDownMd } = useMatchDownMd();

  return (
    <>
      <$Box
        sx={{
          width: SIDEBAR_WIDTH,
          flexShrink: { xs: 1, md: 0 },
          flexGrow: { xs: 1, md: 0 },
          marginLeft: isCollapsed ? "-80px" : "0px",
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
          }),
          backgroundColor: theme.palette.grey[300],
          position: "relative",
        }}
      >
        {!matchDownMd && (
          <CollapseButton
            isCollapsed={isCollapsed ?? false}
            onClick={() => setIsCollapsed && setIsCollapsed(!isCollapsed)}
            sx={{
              right: 5,
              top: "calc(50% - 48px)",
              transform: "translate(100%, -50%)",
              zIndex: 1200,
            }}
          />
        )}
        <$Stack
          sx={{
            paddingTop: 2,
            visibility: isCollapsed ? "hidden" : "visible",
          }}
        >
          <$Box pt={3}>
            <MiniSideBarButton name={"Home"} isActive={true} icon={<HomeOutlined />} />
            <ActionButton />
          </$Box>
          <$Divider
            flexItem
            variant="middle"
            sx={{
              my: 3,
            }}
          />
          <$Stack flex={1}>
            {applications?.map(({ id, displayName, applicationURL, logoURL }) => (
              <SideBarAppButton
                logoURL={logoURL ?? ""}
                key={id}
                applicationId={id.toString()}
                name={displayName}
                redirectURL={applicationURL ?? ""}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.preventDefault();
                  window.open(applicationURL, "_blank");
                }}
              />
            ))}
          </$Stack>
        </$Stack>
      </$Box>
    </>
  );
};
